import type { gsap } from 'gsap';
import { defineModule } from '../utils/helpers';
import { BREAKPOINTS } from '../utils/breakpoints';

const getElements = () => ({
  scrollTriggereElements: document.querySelectorAll<HTMLElement>(
    '[data-scroll-trigger]',
  ),
});
let gs: any;

const timeLines: gsap.core.Timeline[] = [];
let mm: gsap.MatchMedia;
export default defineModule(
  async () => {
    const { scrollTriggereElements } = getElements();
    if (!scrollTriggereElements) return;

    const [{ gsap }, { ScrollTrigger }] = await Promise.all([
      import('gsap'),
      import('gsap/ScrollTrigger'),
    ]);
    gs = gsap;

    gs.registerPlugin(ScrollTrigger);

    mm = gs.matchMedia();
    scrollTriggereElements.forEach((el) => {
      mm.add(
        {
          _isMobile: `(max-width: ${BREAKPOINTS.lg - 1}px)`,
          isDesktop: `(min-width: ${BREAKPOINTS.lg}px)`,
          isReduce: '(prefers-reduced-motion: reduce)',
        },
        (context) => {
          const { isDesktop, isReduce } = context.conditions ?? {};

          const svhEl = document.createElement('div');
          svhEl.style.height = '100svh';
          svhEl.style.position = 'absolute';
          document.body.prepend(svhEl);
          const computedHeight =
            Number(getComputedStyle(svhEl).height) || window.innerHeight;
          svhEl.remove();

          const headerStart = Math.min(
            el.getBoundingClientRect().top + window.scrollY,
            computedHeight,
          );

          const tl = gsap.timeline({
            scrollTrigger: {
              trigger: el,
              scrub: 0.5,
              start: `top ${headerStart}`,
              //   markers: true,
            },
          });
          let xS = '0';
          let xE = '0';
          if (!isReduce) {
            xS = isDesktop ? '5vw' : '50vw';
            xE = isDesktop ? '-5vw' : '-50vw';
          }

          tl.fromTo(
            el,
            {
              x: xS,
            },
            {
              x: xE,
            },
          );
          timeLines.push(tl);
        },
      );
    });

    window.addEventListener('resize', gs.matchMediaRefresh);
  },
  () => {
    window.removeEventListener('resize', gs.matchMediaRefresh);
    while (timeLines.length > 0) {
      timeLines.pop()?.kill();
    }
    mm.kill();
  },
);
